<template>
    <div class="page-content">
        <div class="content-box">
            <div class="form-box" v-loading="loading">
                <SectionTitle title="工作项目" />
                <el-form ref="form" :model="form" label-width="120px" :rules="rules" :disabled="isView">
                    <el-form-item label="项目班：" prop="LayoutId">
                        <el-select class="mr-10" v-model="form.LayoutId" style="width:100%" filterable>
                            <el-option v-for="item in projectClassList" :key="item.Id" :label="item.Name" :value="item.Id"></el-option>
                        </el-select>
                        <!-- <el-select class="mr-10" v-model="form.JobId" style="width:100%" filterable>
              <el-option v-for="item in jobList" :key="item.Id" :label="item.Name" :value="item.Id"></el-option>
            </el-select> -->
                    </el-form-item>
                    <el-form-item label="项目名称：" prop="Name">
                        <el-input v-model="form.Name" placeholder="请输入工作项目名称"></el-input>
                    </el-form-item>
                    <el-form-item label="工作内容：" prop="Content">
                        <el-input type="textarea" v-model="form.Content" placeholder="请输入工作内容" resize="none" rows="5"></el-input>
                    </el-form-item>
                    <el-form-item label="起止时间：" prop="Date">
                        <el-date-picker v-model="form.Date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                    </el-form-item>
                    <template v-for="(item, index) in form.QuotaList">
                        <div :key="item.Id">
                            <div class="title u-flex u-row-between">
                                <SectionTitle :title="`产出指标 [${index + 1}]`" />
                                <span v-if="!isView" class="ml-10">
                                    <i class="el-icon-circle-plus-outline form-add" @click="formItemAdd(index)"></i>
                                    <i v-if="form.QuotaList.length > 1" class="el-icon-remove-outline hover-danger" @click="formItemDelete(index)"></i>
                                </span>
                            </div>
                            <el-form-item label="产出指标名称：">
                                <el-input v-model="item.Name" placeholder="请输入"></el-input>
                            </el-form-item>
                            <el-form-item label="单位：">
                                <el-input v-model="item.Unit" placeholder="请输入"></el-input>
                            </el-form-item>
                            <!-- <el-form-item label="统计方式：">
                <el-input v-model="item.SummaryMethod" placeholder="请输入"></el-input>
              </el-form-item> -->
                            <!-- <el-form-item label="统计来源：">
                <el-input v-model="item.Origin" placeholder="请输入"></el-input>
              </el-form-item> -->
                        </div>
                    </template>
                </el-form>
            </div>
            <div class="footer">
                <el-button v-if="!isView" type="primary" plain @click="handleSave" :loading="btnLoading">保存</el-button>
                <el-button v-if="!isView && isEdit" type="primary" @click="handlePublish" :loading="btnLoading">发布</el-button>
                <el-button type="default" @click="$router.go(-1)">返回</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import SectionTitle from "@/components/SectionTitle.vue";
    import { getInfo, insert, update, publish } from "@/api/internshipWorkItem";
    import { getInternshipJobList } from "@/api/jobApply";
    import contentTools from "@/mixins/content-tools";
    import { StaffTypeEnum } from "@/utils/constant";
    import { mapGetters } from "vuex";
    import { getAllList as getProjectClassList } from "@/api/internshipLayout";
    export default {
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            isView: {
                type: Boolean,
                default: false
            },
            id: {
                type: String,
                default: ""
            }
        },
        components: {
            SectionTitle
        },
        mixins: [contentTools],
        computed: {
            ...mapGetters(["employee"]),
            RoleType() {
                const { matched } = this.$route;
                if (matched.some(({ path }) => path.includes("enterprise"))) {
                    return StaffTypeEnum.Enterprise;
                } else if (matched.some(({ path }) => path.includes("school"))) {
                    return StaffTypeEnum.School;
                }
                return undefined;
            }
        },
        data() {
            return {
                btnLoading: false,
                loading: false,
                form: {
                    QuotaList: [{}]
                },
                jobList: [],
                rules: {
                    JobId: [{ required: true, message: "请选择岗位", trigger: "change" }],
                    LayoutId: [{ required: true, message: "请选择项目班", trigger: "change" }],
                    Name: [{ required: true, message: "请输入项目名称", trigger: "blur" }],
                    Content: [
                        { required: true, message: "请输入项目内容", trigger: "blur" }
                    ],
                    Date: [{ required: true, message: "请选择开始结束", trigger: "change" }]
                },
                EnterpriseId: null,
                projectClassList: []
            };
        },
        created() {
            this.initialize();
            this.getProjectClassList();
        },
        methods: {
            async initialize() {
                try {
                    this.loading = true;
                    if (this.RoleType === StaffTypeEnum.Enterprise) {
                        let temp = null;
                        temp = this.employee.find(el => el.StaffType === this.RoleType);
                        this.EnterpriseId = temp.Employer.Id;
                    } else {
                        this.EnterpriseId = null;
                    }
                    await this.getJobList();
                    if (this.isEdit || this.isView) {
                        await this.getWorkItemInfo();
                    }
                } catch (err) {
                    console.log("err", err);
                } finally {
                    this.loading = false;
                }
            },
            async handleSave() {
                let IsSuccess = false;
                this.$refs["form"].validate(async valid => {
                    if (valid) {
                        try {
                            let [startTime, endTime] = this.form.Date;
                            this.form.StartTime = startTime;
                            this.form.EndTime = endTime;
                            this.form.IsPublished = false;
                            this.btnLoading = true;
                            if (!this.isEdit) {
                                const res = await insert(this.form);
                                IsSuccess = res.IsSuccess;
                            } else {
                                const res = await update(this.form);
                                IsSuccess = res.IsSuccess;
                            }
                            if (IsSuccess) {
                                this.$message.success("保存成功");
                                this.$router.go(-1);
                            } else {
                                this.$message.error("保存失败");
                            }
                        } catch (err) {
                            console.log(err);
                        } finally {
                            this.btnLoading = false;
                        }
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            async handlePublish() {
                try {
                    this.btnLoading = true;
                    const res = await publish(this.id);
                    if (res.IsSuccess) {
                        this.$message.success("发布成功");
                        this.$router.go(-1);
                    }
                } catch (err) {
                    console.log(err);
                } finally {
                    this.btnLoading = false;
                }
            },
            async getWorkItemInfo() {
                const res = await getInfo(this.id);
                if (res.IsSuccess) {
                    this.form = res.Result;
                    let tempTime = [];
                    tempTime.push(this.formartDate(this.form.StartTime, "all"));
                    tempTime.push(this.formartDate(this.form.EndTime, "all"));
                    this.form.Date = tempTime;
                    this.form.QuotaList.map(el => {
                        this.quotaForm = el;
                    });
                }
            },
            async getJobList() {
                const res = await getInternshipJobList({
                    EnterpriseId: this.EnterpriseId
                });
                if (res.IsSuccess) {
                    this.jobList = res.Result;
                }
            },
            formItemAdd(index) {
                this.form.QuotaList.splice(index + 1, 0, {
                    Name: null,
                    Unit: null,
                    SummaryMethod: null
                });
            },
            formItemDelete(index) {
                this.form.QuotaList.splice(index, 1);
            },
            async getProjectClassList() {
                const { Result = [] } = await getProjectClassList();
                this.projectClassList = Result;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .content-box {
        height: calc(100% - 80px);
        overflow: auto;
    }

    .form-box {
        width: 850px;
        margin: 0 auto;
    }

    .title {
        font-weight: bold;
    }

    .footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: #ffffff;
        height: 80px;
        box-shadow: 0px -2px 8px 0px rgba(51, 51, 51, 0.08);
        border-radius: 0px 0px 8px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .form-add {
        color: #409eff;
        font-size: 22px;
        margin-right: 8px;
    }

    .hover-danger {
        font-size: 22px;
    }

    .form-add:hover {
        opacity: 0.8;
    }
</style>